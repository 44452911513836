import React, { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import {
  INewsCard,
  NewsCardClusterType,
  RamblerNewsCard,
} from 'common/components/RamblerComponents/RamblerNewsCard';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectCardById } from 'common/redux/commonData/entries/selectors';
import { useAppSelector } from 'store/hooks';

import { useCardConfig } from '../CardLayout/context';

/**
 * Функция получения модифицированного кластера.
 * @param card - карточка.
 */
export const getCluster = (card: CardData) =>
  ({
    url: card.url,
    title: card.title,
    annotation: card.annotation,
    image: {
      url: card.image.url,
    },
    topic: {
      name: card.topic,
    },
    publication_time: card.publicationTime,
    comments_count: card.commentsCount,
  }) as NewsCardClusterType;

type CardPropsType = {
  className?: INewsCard['className'];
  currentTime: INewsCard['currentTime'];
  type: INewsCard['type'];
  clusterId: CardData['id'];
  index: number;
  isAlternativeVersion?: INewsCard['isAlternativeVersion'];
  onClick?: INewsCard['onClick'];
  isLazy?: INewsCard['isLazy'];
};

/**
 * Карточка кластера.
 * @param props.className - дополнительный класс;
 * @param props.currentTime - текущее время сервера;
 * @param props.type - тип карточки;
 * @param props.clusterId - id кластера;
 * @param props.index - индекс кластера для топ100;
 * @param props.isAlternativeVersion - флаг альтернативной версии, карточки в вечно зеленом;
 * @param props.onClick - функция клика по карточке;
 * @param props.isLazy - флаг ленивой загрузки изображения.
 */
export const Card = withErrorBoundary(function Card({
  className,
  currentTime,
  type,
  clusterId,
  isAlternativeVersion,
  index,
  onClick,
  isLazy,
}: CardPropsType) {
  const card = useAppSelector(selectCardById(clusterId), shallowEqual);
  const { getTop100 } = useCardConfig();
  const top100Attribute = useTop100AttributeWithValue(
    getTop100({ card, index }),
  );

  const cluster = useMemo(() => {
    if (card) {
      return getCluster(card);
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RamblerNewsCard
      className={className}
      currentTime={currentTime}
      type={type}
      projectType="media"
      cluster={cluster}
      isAlternativeVersion={isAlternativeVersion}
      onClick={onClick}
      isLazy={isLazy}
      {...top100Attribute}
    />
  );
});
