import { useEffect, useRef } from 'react';

import { useWidgetStatus } from 'desktop/components/WidgetsStatusProvider';
import { createNode } from 'utils/createNode';

type UseLoadRamblerEmbedPropsType = {
  parentId: string;
  widgetId: string;
  url: string;
};

/**
 * Хук подключения скриптов для ембедов.
 * @param props.parentId - селектор ноды, куда будет вставляться скрипт;
 * @param props.widgetId - id для скрипта;
 * @param props.url - url, по которому подключается скрипт.
 */

export const useLoadRamblerEmbed = ({
  parentId,
  widgetId,
  url,
}: UseLoadRamblerEmbedPropsType) => {
  const embedRef = useRef<HTMLElement>();
  const { statuses, setInitedScript } = useWidgetStatus();

  useEffect(() => {
    // Защита от многоразовой загрузки скрипта
    if (statuses[url]) {
      return;
    }

    setInitedScript(url);

    createNode({
      tagName: 'script',
      type: 'module',
      src: url,
      id: CSS.escape(widgetId),
      /** @see https://stackoverflow.com/questions/73894096/react-useid-creates-invalid-selector */
      root: `#${CSS.escape(parentId)}`,
    });
  }, [parentId, setInitedScript, statuses, url, widgetId]);

  return { embedRef, isInited: statuses[url] };
};
