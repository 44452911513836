import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';

import { useWidgetReachGoal } from 'common/components/metrics/utils';
import { FeedWidgetLayoutRedesign } from 'desktop/components/FeedWidgetLayoutRedesign';

import { CardItem } from './components/CardItem';
import { useTopicNews } from './hooks/useTopicNews';

import s from './styles.module.css';

type TopicNewsWidgetPropsType = {
  topicAlias: string;
  excludedIds?: CardData['id'][];
  newsCount?: number;
  adComponent?: React.ReactNode;
  level?: number;
};

/**
 * Виджет новостей по топику
 * @param props.topicAlias - алиас топика
 * @param props.excludedIds - исключенные из выдачи кластера
 * @param props.level - номер этажа на котором отображается виджет, нужно для отправки цели
 */
export const TopicNewsWidgetRedesign = memo(
  function TopicNewsWidgetRedesign({
    topicAlias,
    excludedIds = [],
    newsCount,
    adComponent,
    level,
  }: TopicNewsWidgetPropsType) {
    const { title, clusters, isError, fetchData } = useTopicNews({
      topicAlias,
      excludedIds,
      limit: newsCount,
    });

    const clustersByCount = newsCount ? clusters.slice(0, newsCount) : clusters;

    const {
      reachGoalNewsClickCallback,
      reachGoalMoreNewsClickCallback,
      reachGoalAdClickCallback,
      widgetRef,
    } = useWidgetReachGoal(level);

    return (
      <div ref={widgetRef}>
        <FeedWidgetLayoutRedesign
          title={title}
          url={`/${topicAlias}/`}
          top100Value={topicAlias}
          isError={isError}
          onRefresh={fetchData}
          onMoreNewsClick={reachGoalMoreNewsClickCallback}
        >
          {clustersByCount.map(
            ({ id, url, title: clusterTitle, commentsCount }, index) => (
              <CardItem
                key={id}
                index={index}
                url={url}
                title={clusterTitle}
                commentsCount={commentsCount}
                onClick={reachGoalNewsClickCallback}
              />
            ),
          )}
          {!!adComponent && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <li className={s.adWrapper} onClick={reachGoalAdClickCallback}>
              {adComponent}
            </li>
          )}
        </FeedWidgetLayoutRedesign>
      </div>
    );
  },
  (prev, next) =>
    prev.topicAlias === next.topicAlias &&
    isEqual(prev.excludedIds, next.excludedIds) &&
    prev.newsCount === next.newsCount,
);
