import React from 'react';

import { Ad } from 'common/components/Ad';
import { RamblerCurrencyConverter } from 'common/components/RamblerEmbeds/RamblerCurrencyConverter';
import { RamblerEmotionalHoroscope } from 'common/components/RamblerEmbeds/RamblerEmotionalHoroscope';
import { RamblerHoroscope } from 'common/components/RamblerEmbeds/RamblerHoroscope';
import { RamblerWeather } from 'common/components/RamblerEmbeds/RamblerWeather';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { PROJECT_IDS } from 'config/constants/projects/constants';

import s from './styles.module.css';

export const SLICE_CONFIG = [5, 5, 5, 9, 9] as const;

export const LAST_CHUNK_SIZE = 9;

const NATIVE_BANNER_LIST_CLASS_NAME =
  'banner--native-context--redesign_list_relaunch';
const NATIVE_BANNER_PRELOAD_HEIGHT = 115;

export const INJECTORS = [
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          loadedStyle={s.adPlaceholderLoaded}
          preloadHeight={100}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          loadedStyle={s.adPlaceholderLoaded}
          preloadHeight={NATIVE_BANNER_PRELOAD_HEIGHT}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={Banners.NativeInRecs}
          puids={puids}
          withoutMarginBottom
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          preloadHeight={164}
          loadedStyle={s.adPlaceholderLoaded}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          preloadHeight={NATIVE_BANNER_PRELOAD_HEIGHT}
          loadedStyle={s.adPlaceholderLoaded}
        />
      );
    }
  },
];

/**
 * Когфиг виджетов повертикально
 */
export const WIDGETS_BY_PROJECT_CONFIG: Record<number, React.ReactNode[]> = {
  [PROJECT_IDS.News]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
  [PROJECT_IDS.Auto]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
  [PROJECT_IDS.Woman]: [
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
    <RamblerWeather key="RamblerWeather" />,
  ],
  [PROJECT_IDS.Sport]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
  ],
  [PROJECT_IDS.Kino]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
  [PROJECT_IDS.Travel]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
  [PROJECT_IDS.Finance]: [
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
    <RamblerWeather key="RamblerWeather" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
  [PROJECT_IDS.Doctor]: [
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerWeather key="RamblerWeather" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
  [PROJECT_IDS.Weekend]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
  [PROJECT_IDS.Family]: [
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
    <RamblerWeather key="RamblerWeather" />,
  ],
  [PROJECT_IDS.Life]: [
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
    <RamblerWeather key="RamblerWeather" />,
  ],
  [PROJECT_IDS.Pro]: [
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
    <RamblerWeather key="RamblerWeather" />,
  ],
  [PROJECT_IDS.Dom]: [
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
    <RamblerWeather key="RamblerWeather" />,
  ],
  [PROJECT_IDS.Ai]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
  [PROJECT_IDS.Sci]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
};
