import React, { useId } from 'react';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';

import { useLoadRamblerEmbed } from '../hooks/useLoadRamblerEmbed';
import s from '../styles.module.css';

const PARENT_ID = 'rambler-horoscope-container';
const WIDGET_ID = 'rambler-horoscope';

/**
 * Компонент с ембедом Рамблера - Гороскоп.
 */
export const RamblerHoroscope = withErrorBoundary(function RamblerHoroscope() {
  const id = useId();

  const { embedRef, isInited } = useLoadRamblerEmbed({
    parentId: `${PARENT_ID}${id}`,
    widgetId: `${WIDGET_ID}${id}`,
    url: 'https://embeds.rambler.ru/horoscopes/horoscope.js',
  });

  return (
    <div id={`${PARENT_ID}${id}`} className={isInited ? '' : s.placeholder}>
      <rambler-horoscope ref={embedRef} />
    </div>
  );
});
