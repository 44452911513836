import cn from 'classnames';
import React, { memo, useMemo } from 'react';

import { Ad } from 'common/components/Ad';
import { Card } from 'common/components/Card';
import { ReachGoalFirstCardWrapper } from 'common/components/metrics/ReachGoalFirstCardWrapper';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { SberWidgetWrapper } from 'common/components/SberWidget';
import {
  selectCurrentServerTime,
  selectIsRussia,
  selectProjectId,
  selectPromoBannerEnabled,
  selectPromoBannerPlaceholderEnabled,
} from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT } from 'config/constants/banner/contants';
import {
  modifyPuids,
  NATIVE_LIST_VERSION_PUIDS,
  PuidsType,
} from 'config/constants/common';
import { TopicNewsWidgetRedesign } from 'desktop/components/TopicNewsWidgetRedesign';
import { useTopicAliasesForWidgets } from 'desktop/hooks/useTopicAliasesForWidgets';
import { useAppSelector } from 'store/hooks';

import { WIDGETS_BY_PROJECT_CONFIG } from '../../constants';

import s from './styles.module.css';

type FirstLevelPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
};

/**
 * Компонент первого этажа новостной вертикали.
 * @param props.clusterIds - массив id кластеров;
 * @param props.puids - файл с настройками рекламы;.
 */
export const FirstLevel = memo(function FirstLevel({
  clusterIds,
  puids,
}: FirstLevelPropsType) {
  const projectId = useAppSelector(selectProjectId);
  const isRussiaRegion = useAppSelector(selectIsRussia);
  const isPromoWidgetEnabled = useAppSelector(selectPromoBannerEnabled);
  const isPromoWidgetPlaceholderEnabled = useAppSelector(
    selectPromoBannerPlaceholderEnabled,
  );
  const serverTime = useAppSelector(selectCurrentServerTime);

  const getTopicsToSideWidgets = useTopicAliasesForWidgets();

  const nativePuids = useMemo(
    () => modifyPuids(puids, NATIVE_LIST_VERSION_PUIDS),
    [puids],
  );

  return (
    <div className={s.root}>
      <div className={s.main}>
        <ReachGoalLevelWrapper level={1} styles={s.news}>
          {clusterIds.map((clusterId, index) => {
            if (index === 0) {
              return (
                <ReachGoalFirstCardWrapper key={clusterId}>
                  <Card
                    key={clusterId}
                    currentTime={serverTime}
                    type="top-first-desktop"
                    clusterId={clusterId}
                    isAlternativeVersion
                    index={index + 1}
                  />
                </ReachGoalFirstCardWrapper>
              );
            }

            return (
              <Card
                key={clusterId}
                currentTime={serverTime}
                type="top-secondary-desktop"
                clusterId={clusterId}
                index={index + 1}
              />
            );
          })}
        </ReachGoalLevelWrapper>
      </div>
      <div className={s.sideWidgets}>
        {!!WIDGETS_BY_PROJECT_CONFIG[projectId] && (
          <div className={s.widget}>
            {WIDGETS_BY_PROJECT_CONFIG[projectId][0]}
          </div>
        )}
        <TopicNewsWidgetRedesign
          topicAlias={getTopicsToSideWidgets(0)}
          newsCount={7}
          level={1}
          adComponent={
            <Ad
              name={Banners.Context}
              puids={nativePuids}
              className={cn(
                s.adPlaceholderList,
                'banner--native-context_widget_relaunch_list',
              )}
              isLazy={false}
              preloadHeight={60}
            />
          }
        />
      </div>
      <div className={s.sideAd}>
        <div className={s.sticky}>
          {isRussiaRegion && isPromoWidgetEnabled && (
            <Ad
              name={Banners.PromoWidget}
              puids={puids}
              isLazy={false}
              /* Рисуем отступ снизу если плейсхолдер виджета включен или он отрендерился */
              className={cn(
                s.promo,
                isPromoWidgetPlaceholderEnabled && s.visible,
              )}
              preloadHeight={PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT}
              disablePlaceholder={!isPromoWidgetPlaceholderEnabled}
            />
          )}
          <SberWidgetWrapper />
          <Ad
            puids={puids}
            name={Banners['240x400']}
            withoutMarginBottom
            isLazy={false}
          />
          {!!WIDGETS_BY_PROJECT_CONFIG[projectId] && (
            <div className={s.widget}>
              {WIDGETS_BY_PROJECT_CONFIG[projectId][0]}
            </div>
          )}
          <Ad
            name={Banners.Context}
            puids={nativePuids}
            className={cn(
              s.adPlaceholder,
              'banner--native-context_widget_relaunch',
            )}
            loadedClassName={s.adPlaceholderLoaded}
            isLazy={false}
            preloadHeight={100}
          />
        </div>
      </div>
    </div>
  );
});
