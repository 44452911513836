import React, { useId } from 'react';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';

import { useLoadRamblerEmbed } from '../hooks/useLoadRamblerEmbed';
import s from '../styles.module.css';

const PARENT_ID = 'rambler-emotional-horoscope-container';
const WIDGET_ID = 'rambler-emotional-horoscope';

/**
 * Компонент с ембедом Рамблера - Эмоциональный гороскоп.
 */
export const RamblerEmotionalHoroscope = withErrorBoundary(
  function RamblerEmotionalHoroscope() {
    const id = useId();

    const { embedRef, isInited } = useLoadRamblerEmbed({
      parentId: `${PARENT_ID}${id}`,
      widgetId: `${WIDGET_ID}${id}`,
      url: 'https://embeds.rambler.ru/horoscopes/emotional-horoscope.js',
    });

    return (
      <div id={`${PARENT_ID}${id}`} className={isInited ? '' : s.placeholder}>
        <rambler-emotional-horoscope ref={embedRef} />
      </div>
    );
  },
);
