import React, { useId } from 'react';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';

import { useLoadRamblerEmbed } from '../hooks/useLoadRamblerEmbed';
import s from '../styles.module.css';

const PARENT_ID = 'rambler-currency-converter-container';
const WIDGET_ID = 'rambler-currency-converter';

/**
 * Компонент с ембедом Рамблера - Конвертер валют.
 */
export const RamblerCurrencyConverter = withErrorBoundary(
  function RamblerCurrencyConverter() {
    const id = useId();

    const { embedRef, isInited } = useLoadRamblerEmbed({
      parentId: `${PARENT_ID}${id}`,
      widgetId: `${WIDGET_ID}${id}`,
      url: 'https://embeds.rambler.ru/finance/currency-converter.js',
    });

    return (
      <div id={`${PARENT_ID}${id}`} className={isInited ? '' : s.placeholder}>
        <rambler-currency-converter ref={embedRef} />
      </div>
    );
  },
);
