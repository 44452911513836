import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectTopicByAlias } from 'common/redux/commonData/topics/selectors';
import { fetchTopicNews } from 'common/redux/commonData/widgets/topicNewsWidget/asyncs';
import {
  selectTopicNewsWidgetClusterInCardData,
  selectTopicNewsWidgetErrorState,
  selectTopicNewsWidgetLoadingState,
} from 'common/redux/commonData/widgets/topicNewsWidget/selectors';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { useAppDispatch } from 'store/hooks';

type UseTopicNewsType = ({
  topicAlias,
  excludedIds,
  limit,
}: {
  topicAlias: string;
  excludedIds?: CardData['id'][];
  limit?: number;
}) => {
  /**
   * Заголовок для виджета
   */
  title: ATTopic['name'];
  /**
   * Массив кластеров для отображения
   */
  clusters: CardData[];
  /**
   * Состояние виджета (есть ошибка или нет)
   */
  isError: boolean;
  /**
   * Функция загрузки данных
   */
  fetchData: () => void;
};

/**
 * Хук для виджета новостей по топику
 * @param props.topicAlias - алиас топика
 * @param props.excludedIds - исключенные из выдачи кластера
 * @param props.limit - кол-во отображаемых кластеров
 */
export const useTopicNews: UseTopicNewsType = ({
  topicAlias,
  excludedIds,
  limit,
}) => {
  const dispatch = useAppDispatch();

  const projectId = useSelector(selectProjectId);
  const topic = useSelector(
    selectTopicByAlias(topicAlias, projectId),
    shallowEqual,
  );

  const { id: topicId, name: title = '' } = topic || {};

  const clusters = useSelector(
    selectTopicNewsWidgetClusterInCardData(excludedIds, topicId, limit),
    shallowEqual,
  );
  const error = useSelector(selectTopicNewsWidgetErrorState(topicId));
  const loading = useSelector(selectTopicNewsWidgetLoadingState(topicId));

  const fetchData = useCallback(() => {
    dispatch(fetchTopicNews({ topicId }));
  }, [dispatch, topicId]);

  useEffect(() => {
    if (!clusters.length && !error && !loading) {
      fetchData();
    }
  }, [clusters.length, error, fetchData, loading]);

  return {
    title,
    clusters: clusters.filter(Boolean) as CardData[],
    fetchData,
    isError: !!error,
  };
};
