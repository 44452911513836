import cn from 'classnames';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { Card } from 'common/components/Card';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { selectCurrentServerTime } from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType, defaultCallback } from 'config/constants/common';
import { useAppSelector } from 'store/hooks';

import s from './styles.module.css';

type InjectorType = (index: number, puids: PuidsType) => React.ReactNode;

type SecondLevelPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
  bottomAdName: Banners;
  level: number;
  widgets?: React.ReactNode;
  injectElements?: InjectorType;
  indexOffset?: number;
  widgetsCompact?: React.ReactNode;
};

/**
 * Компонент для отрисовки новостей начиная со второго экрана для вертикали Новости.
 * @param props.clusterIds - список идентификаторов кластеров;
 * @param props.puids - объект настроек для рекламы;
 * @param props.bottomAdName - название баннера в конце блока;
 * @param props.widgets - компонент с компонентами виджетов для боковой колонки;
 * @param props.widgetsCompact - компонент с компонентами виджетов для боковой колонки на узком экране;
 * @param props.indexOffset - офсет индексов для коректного подсчета в топ100;
 * @param props.injectElements - функция для внедрения компонентов внутрь списка новостей;
 * @param props.level - этаж для передачи в ЯМ.
 */
export const SecondLevel = memo(function RedesignNewsSecondLevel({
  clusterIds,
  puids,
  bottomAdName,
  widgets,
  widgetsCompact,
  indexOffset = 0,
  level,
  injectElements = defaultCallback as InjectorType,
}: SecondLevelPropsType) {
  const serverTime = useAppSelector(selectCurrentServerTime);

  return (
    <ReachGoalLevelWrapper level={level} styles={s.root}>
      <div className={s.content}>
        <div className={s.newsBlock}>
          {clusterIds.map((clusterId, index) => {
            const element = injectElements?.(index, puids);
            const news = (
              <Card
                key={clusterId}
                currentTime={serverTime}
                type="top-secondary-desktop"
                clusterId={clusterId}
                index={index + indexOffset}
                isLazy
              />
            );

            if (element) {
              return [news, element];
            }

            return news;
          })}
        </div>
      </div>
      <div className={s.widgets}>{widgets}</div>
      <div className={cn(s.sideAd)}>
        <div className={cn(s.column, s.sticky)}>
          <Ad
            puids={puids}
            name={Banners['240x400']}
            withoutMarginBottom
            isLazy={false}
          />
          <div className={s.widgetsCompact}>{widgetsCompact}</div>
        </div>
      </div>
      <div className={s.bottomAd}>
        <Ad name={bottomAdName} puids={puids} withoutMarginBottom />
      </div>
    </ReachGoalLevelWrapper>
  );
});
