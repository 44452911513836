import chunk from 'lodash/chunk';
import React, { useMemo } from 'react';

import { Ad } from 'common/components/Ad';
import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { RamblerWidgetsCarousel } from 'common/components/RamblerComponents/RamblerWidgetsCarousel';
import { selectProjectId } from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { TopicNewsWidgetRedesign } from 'desktop/components/TopicNewsWidgetRedesign';
import { useTopicAliasesForWidgets } from 'desktop/hooks/useTopicAliasesForWidgets';
import { useAppSelector } from 'store/hooks';
import { getGlobalIndex } from 'utils/getGlobalIndex';

import { FirstLevel } from './components/FirstLevel';
import { SecondLevel } from './components/SecondLevel';
import {
  INJECTORS,
  LAST_CHUNK_SIZE,
  SLICE_CONFIG,
  WIDGETS_BY_PROJECT_CONFIG,
} from './constants';

import s from './styles.module.css';

import 'desktop/css/banner--native-context_list_relaunch.css';
import 'desktop/css/banner--native-context_widget_relaunch.css';
import 'desktop/css/banner--native-context_widget_relaunch_list.css';

type BaseRedesignOneColumnProps = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
};

const CALENDAR_BUTTONS = [{ label: 'Сегодня', onClick: () => {} }];

/**
 * Базовый костяк для всей домашней страницы вертикалей всех кроме news а также рубрик.
 * @param props.clusterIds - массив id кластеров;
 * @param puids - объект с настройками для рекламы.
 */
export const BaseRedesignOneColumn = withErrorBoundary(
  function BaseRedesignOneColumn({
    puids,
    clusterIds,
  }: BaseRedesignOneColumnProps) {
    const projectId = useAppSelector(selectProjectId);
    const getTopicsToSideWidgets = useTopicAliasesForWidgets();

    const slicedClusters = useMemo(() => {
      const copy = [...clusterIds];

      const sliced = SLICE_CONFIG.map((value) =>
        copy.length > 0 ? copy.splice(0, value) : undefined,
      ).filter((val) => !!val) as string[][];

      if (copy.length <= 0) {
        return sliced;
      }

      return [...sliced, ...chunk(copy, LAST_CHUNK_SIZE)];
    }, [clusterIds]);

    return (
      <div className={s.root}>
        <FirstLevel clusterIds={slicedClusters[0]} puids={puids} />

        <div className={s.bottomBlock}>
          <RamblerWidgetsCarousel
            title="Календарь"
            extraControls={CALENDAR_BUTTONS}
            countToScroll={1}
          >
            {Array.from(new Array(10)).map((_item, index) => (
              <div key={index} className={s.calendarWidget} />
            ))}
          </RamblerWidgetsCarousel>
          <Ad name={Banners['100x70']} puids={puids} withoutMarginBottom />
        </div>

        {slicedClusters.slice(1).map((clusterIds, index) => (
          <SecondLevel
            clusterIds={clusterIds}
            key={index}
            injectElements={
              index >= INJECTORS.length
                ? INJECTORS[INJECTORS.length - 1]
                : INJECTORS[index]
            }
            puids={puids}
            bottomAdName={Banners.Superfooter}
            widgets={
              index === 0 ? (
                WIDGETS_BY_PROJECT_CONFIG[projectId].slice(1)
              ) : (
                <TopicNewsWidgetRedesign
                  topicAlias={getTopicsToSideWidgets(index)}
                  newsCount={7}
                  level={index + 2}
                />
              )
            }
            widgetsCompact={
              WIDGETS_BY_PROJECT_CONFIG[projectId]?.[index + 1] || null
            }
            indexOffset={getGlobalIndex(slicedClusters, index + 1) + 1}
            level={index + 2}
          />
        ))}
      </div>
    );
  },
);
